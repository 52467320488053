// import MaintenancePage from "../../../pages/maintenance/maintenance";
import { StyledMaintenanceContainer } from "./maintenance-container.styles";

const MAINTENANCE = true;

export default function MaintenanceContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  if (!MAINTENANCE) return <>{children}</>;

  // if (MAINTENANCE) return <MaintenancePage />;

  return (
    <StyledMaintenanceContainer>
      {children}
    </StyledMaintenanceContainer>
  );
}
